.add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--left {
  padding-left: 105px;
}
@media (max-width: 1500px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--left {
    padding-left: 70px;
  }
}
@media (max-width: 991px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--left {
    padding-left: 50px;
  }
}
@media (max-width: 767px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--left {
    padding-left: 30px;
  }
}
@media (max-width: 575px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--left {
    padding-left: 15px;
  }
}
.add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--right {
  padding-right: 105px;
}
@media (max-width: 1500px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--right {
    padding-right: 70px;
  }
}
@media (max-width: 991px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--right {
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--right {
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .add-to-bag-container:not(.container) .add-to-bag-block-link.add-to-bag-block-link--right {
    padding-right: 15px;
  }
}